<template>
 <div id="myPurchase">
    <!--导航栏-->
    <van-nav-bar
        :title="title"
        left-text="返回"
        left-arrow
        :fixed=true
        :border=true
        @click-left="onClickLeft"
    />
    <transition name="router-slider" mode="out-in">
      <router-view  style="margin-top:3rem"></router-view>
    </transition>
  </div>
</template>
<script>

export default {
  name: "index",
  data() {
    return {title:'零售管理'};
  },
  created() {},
  watch: {
    $route: {
      handler(to, from) {
        this.title = to.meta.title;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onClickLeft() {
      console.log(this.$route);
      if (this.$route.meta.prev) {
        this.$router.replace(this.$route.meta.prev);
        return;
      }
      this.$router.back();
    },
  },
};
</script>
<style  scoped>
.van-nav-bar--fixed{
position: fixed !important;  
}</style>
